// === CSS
import '../../sass/components/yachts/search-form.scss'

import AlphaHelpers from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-helpers'

const alphaModuleMgr = window.alpha
alphaModuleMgr.addModuleEventListener('bsDropdown', 'loaded', async (evt) => {
  const searchFormWrapperEl = document.querySelector('.search-yachts-form-wrapper')
  if (searchFormWrapperEl) {
    const searchFormEl = searchFormWrapperEl.getElementsByTagName('form')[0]

    // register Bootstrap Dropdown in window object (Bootstrap Select use it to check Bootstrap version)
    const bsDropdown = evt.emitter
    window.bootstrap = window.bootstrap || {}
    window.bootstrap.Dropdown = bsDropdown.getDropdownClass()

    // load Bootstrap Select and its translations
    await import(
      /* webpackChunkName: "bootstrap-select" */
      /* webpackPreload: true */
      '../vendors/bootstrap-select')

    const locale = alphaModuleMgr.getConfig().locale
    await import(
      /* webpackInclude: /(fr_FR|en_US|de_DE|es_ES)/ */
      /* webpackChunkName: "bootstrap-select-[request]" */
      /* webpackPreload: true */
      `bootstrap-select/dist/js/i18n/defaults-${locale}.min`)

    // initialize select items
    const selectPickerElements = AlphaHelpers.smartQuerySelectorAll('.selectpicker')
    selectPickerElements.forEach((selectPickerEl) => {
      const selectPicker = jQuery(selectPickerEl)
      jQuery.fn.selectpicker.call(selectPicker, selectPicker.data())
    })

    // handle mobile display controls
    const searchMobileControls = searchFormWrapperEl.querySelectorAll('.search-yachts-mobile-control')
    searchMobileControls.forEach((searchMobileControlEl) => {
      searchMobileControlEl.addEventListener('click', (e) => {
        e.preventDefault()

        searchFormWrapperEl.classList.toggle('search-open')

        // lock body scroll while form is open on mobile
        const viewportModule = alphaModuleMgr.getModule('viewport')
        if (viewportModule && viewportModule.getViewport().is('<md')) {
          document.body.classList.toggle('lock-body-scroll')
        }
      })
    })

    // handle form autofill (on advertisements archive page)
    const isSearch = document.body.classList.contains('has-yachts-search-form')
    if ('URLSearchParams' in window && isSearch) {
      let hasAnyFilter = false
      const params = new URLSearchParams(window.location.search)
      const data = new FormData()
      const distinctKeys = new Set()
      params.forEach((v, k) => {
        // replace array bracket notation by empty []
        const isMultiple = k.indexOf('[') !== -1
        const selectorKey = k.split('[')[0]
        const paramKey = isMultiple ? `${selectorKey}[]` : selectorKey
        distinctKeys.add(paramKey)

        data.append(paramKey, v)
      })

      for (const key of distinctKeys) {
        // ignore non search query params (ex: cc-ns)
        if (key.indexOf('search-') === -1) {
          continue
        }

        const selectorKey = key.split('[')[0]
        const inputEl = searchFormWrapperEl.querySelector(`[name^=${selectorKey}]`)
        const value = parseInt(data.get(key).toString(), 10)
        const values = data.getAll(key)

        // ignore non search query params (ex: cc-ns)
        if (key.indexOf('search-') === -1) {
          continue
        }

        if (inputEl) {
          switch (key) {
            case 'search-offer[]':
              for (const subValue of values) {
                const subInputEl = document.getElementById(`${key}-${subValue}`)
                if (subInputEl) {
                  subInputEl.checked = true
                  hasAnyFilter = true
                }
              }
              break
            default:
              if (inputEl.classList.contains('selectpicker')) {
                if (values.length) {
                  jQuery(inputEl).selectpicker('val', values)
                  hasAnyFilter = true
                }
              } else if (value >= 0) {
                inputEl.value = value
                hasAnyFilter = true
              }
              break
          }
        }
      }
      if (hasAnyFilter) {
        searchFormEl.classList.add('has-filters')
      }
    }

    const advancedSearchToggleEl = searchFormEl.querySelector('.advanced-search-toggle-btn')
    if (advancedSearchToggleEl) {
      advancedSearchToggleEl.addEventListener('click', (e) => {
        e.preventDefault()

        searchFormEl.classList.toggle('is-advanced-search')
      })
    }

    // clean unused search params
    searchFormEl.addEventListener('formdata', (e) => {
      // remove empty field values
      const formData = e.formData
      let fieldsToDelete = []
      for (const entry of formData.entries()) {
        if (!entry[1].length) {
          fieldsToDelete.push(entry[0])
        }
      }

      fieldsToDelete = [...new Set(fieldsToDelete)]
      fieldsToDelete.forEach((fieldName) => {
        formData.delete(fieldName)
      })
    })
  }
})
